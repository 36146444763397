import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import Tech from "../components/Tech";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import ContactForm from "../components/ContactForm";
import Container from "../components/ui/Container";

function SoftwareDevelopment() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | SOFTWARE DEVELOPMENT COMPANY IN INDIA"
        meta={[
          {
            name: "keywords",
            content:
              "software development company, custom software development, custom software development company, software development services, enterprise software development, top software development companies, software development agency, web application development company, best software development company, healthcare software development company, software development companies near me, software engineering company, custom software development services, enterprise software development company",
          },
          {
            name: "description",
            content:
              "We specialize in providing software development and website designing and development services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />
      <Box position="relative">
        <StaticImage
          src="../images/third-party-header.png"
          alt="Botdecoders Software-development banner image"
          layout="fullWidth"
        />

        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
          fontWeight="bold"
          p={{ base: "4", md: "6", lg: "8" }}
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <HStack>
            <Text color="red.500">SOFTWARE</Text>
            <Text color="white">DEVELOPMENT</Text>
          </HStack>

          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} fontWeight="bold">
            <Text color="white">
              Let&apos;s talk about what we can build together
            </Text>
          </Box>
        </Heading>
      </Box>
      <Container fluid>
        <div>
          <div>
            <div className="grid grid-cols-1 mx-2 md:grid-cols-2">
              <div className="p-6 text-xl space-y-5 text-left">
                <div>
                  <p>
                    We are the team creating USER-FACING SOFTWARE in the form of
                    Web. We are planning, reusing, researching and developing,
                    creating, making things simple, broader usage, etc. Web
                    development, just like software development, is dependent on
                    code.
                  </p>
                </div>
                <div>
                  <p>
                    We specialize in providing web Designing and development
                    services including solution integration and offshore{" "}
                    <span className="font-bold">IT</span>
                    outsourcing to small and medium sized businesses{" "}
                    <span className="font-bold">(SMB)</span>
                    across the globe. Go with us and you can benefit from our
                    experience, with amazing range of skills, deep vertical
                    industries expertise and excellence in top technologies,
                    like
                    <span className="font-bold">
                      Html, Html5, .NET, PHP, Data Base Management, ERP
                    </span>
                    <span className="font-bold">
                      Business Solution and various other custom application
                    </span>
                    <span className="font-bold">fields.</span>
                  </p>
                </div>
              </div>
              <div className="relative md:w-3/4 mt-8 md:-top-36 md:left-10">
                <ContactForm />
              </div>
            </div>
          </div>
          <Tech />
        </div>
      </Container>
    </Layout>
  );
}

export default SoftwareDevelopment;
